<template>
  <el-form
    ref="form"
    :inline="true"
    :model="filters"
    :rules="rules"
    @submit.native.prevent
  >
    <el-form-item prop="company" class="company-select">
      <el-select
        :value="filters.company"
        placeholder="Select Company"
        filterable
        @change="handleCompanyChange"
      >
        <el-option
          v-for="company in companies"
          :key="company.value"
          :label="company.label"
          :value="company.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item prop="states" class="state-select">
      <el-select
        v-model="filters.states"
        placeholder="All States"
        filterable
        clearable
        multiple
        collapse-tags
        @clear="clear('states')"
        @change="search"
      >
        <el-option
          v-for="state in stateOptions"
          :key="state.value"
          :label="state.label"
          :value="state.value"
        />
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import { constants } from '@/utils/constants'

export default {
  name: 'MapFilters',
  data () {
    return {
      filters: {
        company: parseInt(this.$route.params.cid),
        states: []
      },
      stateOptions: constants.states,
      rules: {
        company: [{ required: true, message: 'This field is required', trigger: 'blur' }]
      }
    }
  },
  computed: {
    companies () {
      return this.$store.state.companies.data.map(company => {
        return {
          label: company.human_name,
          value: company.id
        }
      })
    }
  },
  mounted () {
    this.search()
  },
  methods: {
    /**
     * Validate form and emit search event for parent to handle.
     */
    search () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$emit('search', this.filters)
        }
      })
    },
    /**
     * Clear the target filter.
     *
     * @param {String} filter
     */
    clear (filter) {
      this.filters[filter] = []
      this.$emit('search', this.filters) // don't validate
    },
    /**
     * Handler for when the company selection has changed
     *
     * @param {Number} company - id
     */
    handleCompanyChange (company) {
      this.$router.push({ name: this.$route.name, params: { cid: company } })
      this.filters.company = company
      this.search()
    }
  }
}
</script>

<style scoped>
.company-select .el-select {
  width: 300px;
}
</style>
