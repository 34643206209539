<template>
  <div id="map-container" />
</template>

<script>
/**
 * HEREMap
 *
 * Creates an interactive map similar to google maps.
 *
 * Markers can be displayed on the map by passing in the
 * `markers` prop. Each marker is an object with two attributes:
 *
 * address {Object} - street, city, state, country, postal, latitude, longitude
 * [html] {String} - marker data to be displayed in an info bubble
 */
import { loadLink, loadScript } from '@/utils'
import { formatAddress } from '@/utils/rooof'

// Replace the default HERE map marker, it's very ugly
const svgMarker = `<svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 264.938 264.938" fill="#2f6eae">
  <g>
    <path d="M156.831,70.804c0,13.473-10.904,24.396-24.357,24.396c-13.434,0-24.357-10.923-24.357-24.396
    c0-13.434,10.904-24.337,24.357-24.337C145.927,46.467,156.831,57.37,156.831,70.804z M203.298,70.795
    c0,8.764-1.661,17.098-4.563,24.836c-9.282,27.571-70.736,169.307-70.736,169.307S70.14,110.403,65.118,92.68
    c-2.237-6.868-3.478-14.196-3.478-21.866C61.64,31.743,93.354,0,132.474,0C171.593-0.01,203.307,31.733,203.298,70.795z
    M177.661,71.078c0-24.953-20.214-45.197-45.187-45.197c-24.953,0-45.177,20.234-45.177,45.187s20.224,45.187,45.177,45.187
    C157.446,116.255,177.661,96.031,177.661,71.078z"/>
  </g>
</svg>`

export default {
  name: 'HEREMap',
  props: {
    options: {
      type: Object,
      default: () => ({
        zoom: 4,
        center: { lat: 37.0902, lng: -95.7129 }
      })
    },
    markers: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    /**
     * Re-draw new markers on prop change.
     */
    markers () {
      if (this.map) {
        this.map.removeObjects(this.map.getObjects())
        this.addMarkers()
      }
    }
  },
  /**
   * Load external HERE map libraries.
   *
   * The order in which resources are loaded is important,
   * as they can depend on globally injected variables.
   */
  async mounted () {
    this.resources = [
      await loadLink('https://js.api.here.com/v3/3.1/mapsjs-ui.css'),
      await loadScript('https://js.api.here.com/v3/3.1/mapsjs-core.js'),
      await loadScript('https://js.api.here.com/v3/3.1/mapsjs-service.js'),
      await loadScript('https://js.api.here.com/v3/3.1/mapsjs-ui.js'),
      await loadScript('https://js.api.here.com/v3/3.1/mapsjs-mapevents.js')
    ]
    this.platform = new window.H.service.Platform({
      'apikey': 'PzybzijHBCU0vaZD6UrojQXlSpFF5zNRCFw0nn2MJzY'
    })
    // Initialize the geocoding service
    this.service = this.platform.getSearchService()
    // Create the marker svg icon
    this.icon = new window.H.map.Icon(svgMarker)

    this.setUp()
    this.addMarkers()
  },
  /**
   * Unload external libraries.
   */
  beforeDestroy () {
    this.resources.forEach(resource => {
      document.head.removeChild(resource)
    })
  },
  methods: {
    /**
     * Initialize the interactive map instance.
     */
    setUp () {
      const defaultLayers = this.platform.createDefaultLayers()
      const defaultOptions = {
        zoom: this.options.zoom,
        center: this.options.center,
        pixelRatio: window.devicePixelRatio || 1
      }
      this.map = new window.H.Map(
        document.getElementById('map-container'),
        defaultLayers.vector.normal.map,
        defaultOptions
      )
      // Make the map interactive
      const eventSystem = new window.H.mapevents.MapEvents(this.map)
      /* eslint-disable no-new */
      new window.H.mapevents.Behavior(eventSystem)

      // Add a resize listener to make sure the map occupies the whole container
      window.addEventListener('resize', () => this.map.getViewPort().resize())

      // Add the default ui components (zoom, controls)
      this.ui = window.H.ui.UI.createDefault(this.map, defaultLayers)
    },
    /**
     * Create a new group (container for other map objects)
     * and adds it to the map object.
     *
     * A listener for the `tap` event will also be added, which
     * is triggered when the user clicks a marker.
     *
     * @returns {H.map.Group}
     */
    createGroup () {
      const group = new window.H.map.Group()
      this.map.addObject(group)

      // Add 'tap' event listener to the group (onclick action)
      group.addEventListener('tap', event => {
        const bubble = new window.H.ui.InfoBubble(event.target.getGeometry(), {
          content: event.target.getData()
        })
        // Display the info bubble with custom marker data
        this.ui.addBubble(bubble)
      }, false)

      return group
    },
    /**
     * Add a series of markers to a marker group.
     *
     * If marker coordinates are not provided, an
     * attempt to discover them will be made using
     * the geocoding api (non-blocking).
     */
    addMarkers () {
      const markerGroup = this.createGroup()
      for (const marker of this.markers) {
        marker.coords = {
          lat: marker.address.latitude,
          lng: marker.address.longitude
        }
        if (marker.coords.lat === null || marker.coords.lng === null) {
          this.geocode(marker.address, coords => {
            marker.coords = coords
            this.addMarker(marker, markerGroup)
          })
        } else {
          this.addMarker(marker, markerGroup)
        }
      }
      // Resize the map to the smallest possible bounds
      // (give it a bit of time for any geocode requests to complete)
      setTimeout(() => {
        const bounds = markerGroup.getBoundingBox()
        if (bounds !== null) {
          this.map.getViewModel().setLookAtData({ bounds })
        }
      }, 1500)
    },
    /**
     * Adds a marker to the interactive map at the given coordinates.
     *
     * If a group is provided, the marker will be added there instead.
     * Adding a marker to a group allows it to display custom html
     * content in an info bubble when that marker is clicked.
     *
     * @param {Object} marker
     * @param {H.geo.Point} marker.coords - the location of the marker
     * @param {String} [marker.html] - (optional) data associated with the marker, displayed in an info bubble
     * @param {H.map.Group} [group] - (optional) the marker group
     */
    addMarker (marker, group = null) {
      if (marker.html && !group) {
        throw new Error('group: this field is required in order to display custom marker data')
      }
      const obj = new window.H.map.Marker(marker.coords, { icon: this.icon })
      const target = marker.html ? group : this.map
      if (marker.html) {
        obj.setData(marker.html)
      }
      target.addObject(obj)
    },
    /**
     * Given an address, perform a geocode lookup to
     * determine the coordinates.
     *
     * @param {Object} address
     * @param {Function} callback - function to execute on success
     */
    geocode (address, callback) {
      const query = formatAddress(address)
      if (query !== '') {
        this.service.geocode({
          q: query
        }, result => {
          // Return the first matched location
          if (result.items.length > 0) {
            callback(result.items[0].position)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
#map-container {
  height: 100%;
  width: 100%;
}
</style>
