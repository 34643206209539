<template>
  <div id="property-map">
    <el-row>
      <el-col>
        <h1>
          Property Map
          <span v-if="markers.length">
            ({{ markers.length }})
          </span>
        </h1>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <map-filters @search="fetchData" />
      </el-col>
    </el-row>
    <el-row>
      <el-col class="map">
        <here-map
          v-loading="loading"
          :markers="markers"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import MapFilters from './_components/MapFilters'
import HEREMap from '@/components/maps/HEREMap'

import RooofAPI from '@/services/api/rooof'

/**
 * Given a valid address object, format
 * it as a collection of html markup.
 *
 * @param {Object} address
 * @returns {String}
 */
export function htmlAddress (address) {
  if (!address) {
    return ''
  }
  return `
    <div>${address.street}</div>
    <div>${address.city}, ${address.state}</div>
    <div>${address.country}, ${address.postal}</div>
  `
}

export default {
  name: 'PropertyMap',
  components: {
    'map-filters': MapFilters,
    'here-map': HEREMap
  },
  data () {
    return {
      loading: false,
      properties: []
    }
  },
  computed: {
    markers () {
      return this.properties.filter(prop => prop.address !== null).map(prop => {
        return {
          address: prop.address,
          html: `
            <div style="width: 150px">
              <div><b>${prop.name}</b></div>
              <div style="font-size: 12px">${htmlAddress(prop.address)}</div>
            </div>
          `
        }
      })
    }
  },
  methods: {
    async fetchData (filters) {
      try {
        this.loading = true

        const params = new URLSearchParams()
        for (const state of filters.states) {
          params.append('state', state)
        }

        this.properties = await RooofAPI.companies.propertySummary(filters.company, params)
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
h1 {
  margin: 0 0 0.5em;
  font-size: 2rem;
}
.map {
  height: 700px;
}
</style>
